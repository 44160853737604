@font-face {
  font-family: "Heathergreen";
  src: url("./assets/fonts/Heathergreen.otf");
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Medium.otf");
}
@font-face {
  font-family: "Inter Bold";
  src: url("./assets/fonts/Inter-ExtraBold.otf");
}

body {
  margin: 0;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter" !important;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}